<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <validation-provider
        v-slot="{ errors }"
        :rules="rules"
      >
        <v-text-field
          v-model="selectedTextFieldColor"
          :error-messages="errors"
          :label="label"
          v-bind="attrs"
          v-on="on"
          readonly
          class="pt-0"
          :disabled="disabled"
        >
          <template v-slot:append-outer>
            <v-icon
              :style="{ color: selectedTextFieldColor }"
              @click="menu = !menu"
              :disabled="disabled"
            >
              mdi-palette
            </v-icon>
          </template>
        </v-text-field>
      </validation-provider>
    </template>
    <v-color-picker
      v-model="selectedPickerColor"
      @input="handleColorPickerInput"
      dot-size="25"
      hide-mode-switch
      mode="hexa"
      swatches-max-height="200"
      :disabled="disabled"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'InputColor',
  props: {
    label: {
      type: String,
      default: 'Cor'
    },
    selectedColor: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default() {
        return {
          min: 7
        };
      }
    }
  },
  data() {
    return {
      selectedTextFieldColor: null,
      selectedPickerColor: null,
      menu: false
    };
  },
  mounted() {
    if (this.selectedColor) {
      this.selectedTextFieldColor = this.selectedColor;
    }
  },
  watch: {
    menu() {
      if (this.menu === false) {
        this.$emit('colorSelected', this.selectedTextFieldColor);
      }
    },
    selectedColor() {
      if (this.selectedColor) {
        this.selectedTextFieldColor = this.selectedColor;
      }
    }
  },
  methods: {
    handleColorPickerInput(e) {
      this.selectedTextFieldColor = e.hex;
    }
  }
};
</script>
